
import { mixins, Options } from 'vue-class-component';
import OAuthWalletMixin from '@/mixins/oAuthWallet';

@Options({
  props: {
    showBalance: {
      type: Boolean,
      default: true
    },
    showChain: {
      type: Boolean,
      default: true
    },
    connectWalletImage: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    }
  }
})
export default class OAuthConnect extends mixins(OAuthWalletMixin) {
  showBalance!: boolean
  showChain!: boolean
  connectWalletImage!: string;
  avatar!: string;

  showDisconnectMenu = false

  toggleDisconnectMenu () : void {
    if (!this.wrongChain) {
      this.showDisconnectMenu = !this.showDisconnectMenu;
    }
  }

  closeDisconnectMenu () : void {
    this.showDisconnectMenu = false;
  }

  async disconnectAndClose () : Promise<void> {
    this.closeDisconnectMenu();
    await this.disconnectWallet();
  }
}
