
import { mixins } from 'vue-class-component';
import MultiWalletMixin from '@/mixins/multiWallet';

export default class MultiConnect extends mixins(MultiWalletMixin) {
  showConnectOptions = false

  setShowConnectOptions () : void {
    if (!this.wrongChain) {
      this.showConnectOptions = !this.showConnectOptions;
    }
  }

  closeConnectOptions () : void {
    this.showConnectOptions = false;
  }

  async disconnectAndClose () : Promise<void> {
    this.closeConnectOptions();
    await this.disconnectWallet();
  }
}
