// @ts-nocheck
import { createApp, createVNode, render } from 'vue';

export const renderComponentWithVNode = ({ el, component, props }) => {
  let vnode = createVNode(component, props);
  render(vnode, el);

  return () => {
    // destroy vnode
    render(null, el);
    vnode = undefined;
  };
};

export const renderComponentWithApp = ({ el, component, props }) => {
  let app = createApp(component, props);
  app.mount(el);

  return () => {
    // destroy app/component
    app?.unmount();
    app = undefined;
  };
};
