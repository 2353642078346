
import { Options, Vue } from 'vue-class-component';
import { OAuthGrantType } from '@manifoldxyz/manifold-sdk';
import Connect from '@/lib-components/Connect.vue';
import MinimalConnect from '@/lib-components/MinimalConnect.vue';
import MultiConnect from '@/lib-components/MultiConnect.vue';
import OAuthConnect from '@/lib-components/OAuthConnect.vue';
import OAuthMultiConnect from '@/lib-components/OAuthMultiConnect.vue';

@Options({
  components: {
    Connect,
    MinimalConnect,
    MultiConnect,
    OAuthConnect,
    OAuthMultiConnect
  },
  props: {
    showBalance: {
      default: true,
      required: false,
      type: Boolean
    },
    connectWalletImage: {
      default: '',
      required: false,
      type: String
    },
    showChain: {
      default: true,
      required: false,
      type: Boolean
    },
    avatar: {
      default: '',
      required: false,
      type: String
    },
    multi: {
      default: false,
      required: false,
      type: Boolean
    },
    minimal: {
      default: false,
      required: false,
      type: Boolean
    },
    fallbackProvider: {
      type: String,
      required: false
    },
    network: {
      type: Number,
      required: false
    },
    parentFrameUrl: {
      type: String,
      required: false
    },
    autoReconnect: {
      default: true,
      type: Boolean,
      required: false
    },
    overrideConnectText: {
      default: '',
      required: false,
      type: String
    },
    walletProvider: {
      type: String,
      required: false
    },
    strictAuth: {
      type: Boolean,
      default: true,
      required: false
    },
    delayAuth: {
      type: Boolean,
      default: false,
      required: false
    },
    clientId: {
      type: String,
      required: false,
      default: ''
    },
    appName: {
      type: String,
      required: false,
      default: ''
    },
    grantType: {
      type: String,
      required: false,
      default: OAuthGrantType.SIGNATURE
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  }
})
export default class MConnect extends Vue {
  showBalance!: boolean;
  connectWalletImage!: string;
  showChain!: boolean;
  avatar!: string;
  multi!: boolean;
  minimal!: boolean;
  fallbackProvider!: string;
  network!: string;
  parentFrameUrl!: string;
  autoReconnect!: boolean;
  overrideConnectText!: string;
  walletProvider!: string;
  strictAuth!: boolean;
  delayAuth!: boolean;
  clientId!: string;
  appName!: string;
  grantType!: string;
  message!: string;

  created () : void {
    if (this.multi && this.minimal) {
      throw Error('Props multi and minimal are mutually exclusive');
    }

    if ((this.appName || this.clientId) && !(this.appName && this.clientId)) {
      throw Error('Props app-name and client-id must be provided together');
    }
  }
}
